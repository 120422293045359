/* body{
  background: rgb(11, 1, 21);
} */


.scale{
  
  transition: .5s;
  cursor:pointer;
}
.scale:hover {
  scale: 1.05;
}
.hero-text {
  
  height: 100vh;
  color: white;
  padding: 50px;
  background: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0.686)), url('./Assets/background.jpg');
  display: flex;
  background-repeat: no-repeat;
  background-size: 100vw;
  flex-direction: column;
  justify-content: center;
}

.hero-text2 {
  
  height: 100vh;
  color: white;
  padding: 50px;
  background: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0.686)), url('./Assets/earnbig.jpg');
  display: flex;
  background-repeat: no-repeat;
  background-size: 100vw;
  flex-direction: column;
  justify-content: center;
}
@media(max-width:500px){
  .hero-text2{
    height: 39vh;
  }
}
.hero-image {
  flex: 1;
  background-image: url('./Assets/background.jpg');
  background-size: cover;
  background-position: center;
}

.custom-shape-divider-bottom-1731808011 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1731808011 svg {
  position: relative;
  display: block;
  width: calc(194% + 1.3px);
  height: 354px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1731808011 .shape-fill {
  fill: #ffffff;
}
.custom-shape-divider-bottom-1731809408 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1731809408 svg {
  position: relative;
  display: block;
  width: calc(126% + 1.3px);
  height: 189px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1731809408 .shape-fill {
  fill: #FFFFFF;
}


button {
  background-color: transparent;
  border: solid 1px rgb(73, 14, 105);
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
}

button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgb(73, 14, 105);
  
  transition: left 0.4s ease;
  z-index: 0;
}

button:hover::before {
  left: 0;
}

button:hover {
  color: white;
}

button span {
  position: relative;
  z-index: 1;
}
.scroll-section {
  margin-top:100px ;
  margin-bottom: 100px;
  opacity: 0;
  background-color: #ffffff;
  transform: translateY(70px);
  transition: opacity 2.4s ease, transform 2.4s ease;
}

.scroll-section.visible {
  opacity: 1;
  transform: translateY(0);
}


.scroll-section h2 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.scroll-section p {
  font-size: 1.2rem;
  color: #555;
  margin: 0;
  padding: 0 20px;
}


.testimonials{
  margin-top: 100px;
  text-align: center;
}

.k200{
  background:linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.545)), url('./Assets/testimonial1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding: 30px
}

.k300{
  background:linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.601)), url('./Assets/back.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding: 30px;
}

.k320{
  background:linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.573)), url('./Assets/testimonial2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding: 30px;
  transition: 1s all ease-in-out;
}


.who{
  margin: 100px;
  width: 100vw;
  background: purple;
}

.custom-shape-divider-bottom-1731878626 {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1731878626 svg {
  position: relative;
  display: block;
  width: calc(148% + 1.3px);
  height: 194px;
}

.custom-shape-divider-bottom-1731878626 .shape-fill {
  fill: #ffffff;
}